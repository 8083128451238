import "./App.css";
import toggle from "./toggle.png";
import banner from "./banner.jpg";
import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import img4 from "./img4.jpg";
import img5 from "./img5.jpg";
import img6 from "./img6.jpg";
import img7 from "./img7.jpg";
import banner2 from "./banner2.jpg";
import banner3 from "./banner3.jpg";
import destination1 from "./destination1.jpg";
import destination2 from "./destination2.jpg";
import destination3 from "./destination3.jpg";
import facebook from "./facebook.png";
import instagram from "./instagram.png";
import met1 from "./met1.jpg";
function App() {
	return (
		<>
			<section className='header'>
				<a href='http://' className='logo'>
					<h2>Srushti & Swagat's Story</h2>
				</a>
			</section>
			<section className='banner'>
				<img src={banner} alt='banner' className='fitBg' />
				<div className='content'>
					<h2>let's redefine this beautiful relationship!</h2>
					<p>
						hey babe! we have been dating for eight months now.
						within such a short period, you have undoubtedly become
						the most important person in my life. you are my peace,
						my strength, my happiness and my sanity. there is no
						'swagat' without 'srushti'. before I ask you to close
						this page, let's celebrate our journey together!
					</p>
					<a href='#more' className='btn'>
						start celebrating!
					</a>
				</div>
			</section>
			<section className='about' id='more'>
				<div className='contentBx'>
					<h2 className='titleText'>how We met each other!</h2>
					<p className='text'>
						i can't express how lucky i feel each day when i recall
						the fact that i am with you. how fortunate a guy that he
						gets a text from such a beautiful and dreamy girl asking
						him his number. although you might think that i played
						hard at first, but believe me i was so into you from the
						start only! i still remeber how nervous i was when i
						asked you out but you were not replying after the
						question. you made my heart do a little ballet of its
						own.
					</p>
					<a href='#moree' className='btn'>
						next!
					</a>
				</div>
				<div className='imgBx'>
					<img src={met1} alt='img1' className='fitBg' />
				</div>
			</section>
			<section className='about' id='moree'>
				<div className='imgBx'>
					<img src={img2} alt='img1' className='fitBg' />
				</div>
				<div
					className='contentBx'
					style={{ textAlign: "start", paddingLeft: "40px" }}
				>
					<h2 className='titleText'>
						We took time to understand each other
					</h2>
					<p className='text'>
						honestly it seemed very hard at first; managing the
						academic and other things while also keeping you happy.
						We used to fight a lot, but slowly and gradually i
						understood your morality, your principles and your
						greatness, i hope you have understood me too! after that
						every other trouble seemed irrelevant infront of your
						smile. you give me strenght babe, you really do! no task
						seems impossible now that i know that you are with me!
					</p>
					<a href='#moreee' className='btn'>
						next!
					</a>
				</div>
			</section>
			<section className='about' id='moreee'>
				<div className='contentBx'>
					<h2 className='titleText'>
						We started trusting each other deeply!
					</h2>
					<p className='text'>
						every moment We shared together made me closer to you;
						wether it be video call or in-real. you have certainly
						gained a high place in my heart. i really adore and
						respect you a lot! being with you is an honour! you
						really a great soul babe, an angel from the skies!
						although it always seems difficult to you now but i
						guarantee that you will certainly succeed in your life
						and i will always be there to support you in every move.
					</p>
					<a href='#moreeee' className='btn'>
						next!
					</a>
				</div>
				<div className='imgBx'>
					<img src={img3} alt='img1' className='fitBg' />
				</div>
			</section>
			<section className='about' id='moreeee'>
				<div className='imgBx'>
					<img src={img4} alt='img1' className='fitBg' />
				</div>
				<div
					className='contentBx'
					style={{ textAlign: "start", paddingLeft: "40px" }}
				>
					<h2 className='titleText'>good food and good company!</h2>
					<p className='text'>
						every date with you was a blessing! We are meant to be
						babe. We really are! never have i ever enjoyed anyone's
						company as much as i enjoy yours. your laugther, your
						cute voice centered around b flat and our never ending
						conversations; time passes away so quickly that we never
						feel tired around each other. and do you still remember
						all the food we had! uncountable and ever so tasty.
						perhaps the best meals of my life are with you
					</p>
					<a href='#moreeeee' className='btn'>
						next!
					</a>
				</div>
			</section>
			<section className='about' id='moreeeee'>
				<div className='contentBx'>
					<h2 className='titleText'>looking so good together!</h2>
					<p className='text'>
						every time i see one of Our photos, it makes me wonder
						how perfect destiny can be. how did two amazingly good
						human being end up with each other in such a massive
						world. what plans did fate make to bring us together?
						how did a guy like me end up with a girl as amazing as
						you. all these questions makes me wonder and puts a
						smile on my face at the same time
					</p>
					<a href='#moreeeeee' className='btn'>
						next !
					</a>
				</div>
				<div className='imgBx'>
					<img src={img5} alt='img1' className='fitBg' />
				</div>
			</section>
			<section className='about' id='moreeeeee'>
				<div className='imgBx'>
					<img src={img6} alt='img1' className='fitBg' />
				</div>
				<div
					className='contentBx'
					style={{ textAlign: "start", paddingLeft: "40px" }}
				>
					<h2 className='titleText'>enjoying each others company!</h2>
					<p className='text'>
						every moment We spend together is so fun and full of
						laughter! making you laugh and laughing with you is my
						favourite activity throughout the day. look at how
						beautiful you look when you are with me! how happy you
						become after talking with me. although i mess up
						sometime and make you angry or sad but believe me babe i
						would never do anything to stop this beautiful smile
						intentionally!
					</p>
					<a href='#moreeeeeee' className='btn'>
						next!
					</a>
				</div>
			</section>
			<section className='about' id='moreeeeeee'>
				<div className='contentBx'>
					<h2 className='titleText'>being nautankis till death!</h2>
					<p className='text'>
						okay!!! now some people might say We went too far with
						this pic. but who cares right! who cares what people
						think about Us and what they might say. We are here to
						celebrate a good life with each other's company. to be
						happy all the time. to be there for each other. believe
						me babe, all i want to hear in my life is when we get
						old i want you beside me sipping some hot coffee and
						assuring me that we made it and that i was a good man
						and i lived a good life with you.
					</p>
					<a href='#moreeeeeeee' className='btn'>
						next!
					</a>
				</div>
				<div className='imgBx'>
					<img src={img7} alt='img1' className='fitBg' />
				</div>
			</section>
			<section className='about' id='moreeeeeeee'>
				<div className='imgBx'>
					<img src={img1} alt='img1' className='fitBg' />
				</div>
				<div
					className='contentBx'
					style={{ textAlign: "start", paddingLeft: "40px" }}
				>
					<h2 className='titleText'>why should you say yes!</h2>
					<p className='text'>
						i never had the courage to ask this to you. it always
						seemed like a dream to me. i don't know what your answer
						will be but before you decide i want to say that i will
						always take care of you and love you from the bottom of
						my heart. i will never ever take any path that takes me
						away from me. all my life i will make sure you are happy
						and that nothing is ever less to us. that i earn enough
						for us both and that i let you do whatever you wanna do
						in life. i promise to be a good partner!
					</p>
					<a href='#banner2' className='btn'>
						next!
					</a>
				</div>
			</section>
			{/* <section className='about' id='more'>
				<div className='contentBx'>
					<h2 className='titleText'>Feel the Beauty of The World</h2>
					<p className='text'>
						Lorem, ipsum dolor sit amet consectetur adipisicing
						elit. Atque repellendus quae at, cupiditate quam enim
						exercitationem rem sed repellat officiis voluptates! Sit
						quis, cupiditate modi laborum quae quisquam perferendis
						labore? Dicta natus assumenda non nobis, magni nemo
						laborum, voluptates laudantium error esse maxime quo,
						hic perferendis similique inventore. Iusto, sunt
						pariatur? Aliquam enim, eligendi non maxime atque
						necessitatibus illum. Velit! Rerum laudantium,
						cupiditate magnam a ipsum quidem inventore tenetur
						distinctio architecto. Eligendi quod unde ut ab
						accusantium autem quae magni, dolorem delectus
						cupiditate deleniti iste consectetur animi velit soluta
						error. Laborum totam, asperiores reiciendis quidem
						nesciunt dolorum vitae optio natus iste veritatis
						voluptate deserunt voluptatibus debitis doloremque culpa
						vero alias commodi quo? Libero dolorem quibusdam tempore
						adipisci soluta reprehenderit rerum. Tempore dolorem
						perspiciatis aut animi sit similique, expedita rerum
						magnam facilis voluptates, culpa corporis commodi
						debitis dolorum molestiae dolore ea sapiente quia
						asperiores soluta voluptatum quis, aliquid dolor odio.
						Vel? Quod impedit minima molestias earum quo, dolores
						ullam enim nostrum maiores illo ea consequuntur officiis
						velit fugiat assumenda perferendis, blanditiis eligendi
					</p>
					<a href='#moree' className='btn'>
						Popular Destination
					</a>
				</div>
				<div className='imgBx'>
					<img src={img1} alt='img1' className='fitBg' />
				</div>
			</section>
			<section className='about' id='moree'>
				<div className='imgBx'>
					<img src={img1} alt='img1' className='fitBg' />
				</div>
				<div
					className='contentBx'
					style={{ textAlign: "start", paddingLeft: "40px" }}
				>
					<h2 className='titleText'>Feel the Beauty of The World</h2>
					<p className='text'>
						Lorem, ipsum dolor sit amet consectetur adipisicing
						elit. Atque repellendus quae at, cupiditate quam enim
						exercitationem rem sed repellat officiis voluptates! Sit
						quis, cupiditate modi laborum quae quisquam perferendis
						labore? Dicta natus assumenda non nobis, magni nemo
						laborum, voluptates laudantium error esse maxime quo,
						hic perferendis similique inventore. Iusto, sunt
						pariatur? Aliquam enim, eligendi non maxime atque
						necessitatibus illum. Velit! Rerum laudantium,
						cupiditate magnam a ipsum quidem inventore tenetur
						distinctio architecto. Eligendi quod unde ut ab
						accusantium autem quae magni, dolorem delectus
						cupiditate deleniti iste consectetur animi velit soluta
						error. Laborum totam, asperiores reiciendis quidem
						nesciunt dolorum vitae optio natus iste veritatis
						voluptate deserunt voluptatibus debitis doloremque culpa
						vero alias commodi quo? Libero dolorem quibusdam tempore
						adipisci soluta reprehenderit rerum. Tempore dolorem
						perspiciatis aut animi sit similique, expedita rerum
						magnam facilis voluptates, culpa corporis commodi
						debitis dolorum molestiae dolore ea sapiente quia
						asperiores soluta voluptatum quis, aliquid dolor odio.
						Vel? Quod impedit minima molestias earum quo, dolores
						ullam enim nostrum maiores illo ea consequuntur officiis
						velit fugiat assumenda perferendis, blanditiis eligendi
					</p>
					<a href='#' className='btn'>
						Popular Destination
					</a>
				</div>
			</section> */}
			<section className='banner2' id='banner2'>
				<img src={banner2} alt='banner2' className='fitBg' />
			</section>
			<section className='destination'>
				<div className='content'>
					<h2 className='titleText'>Future Destinations</h2>
					<p className='text'>
						well srushti, you didn't only find a life partner in me,
						rather more than it. We will be amazing best friends and
						above all crazy travel partners. So here are some
						destinations we might plan to visit or live in the near
						future. imagine all these places and their beauty with
						me.
					</p>
				</div>
				<div className='destinationList'>
					<div className='box'>
						<div className='imgBx'>
							<img
								src={destination1}
								alt='d2'
								className='fitBg'
							/>
						</div>
						<div className='content'>
							<h2>
								Lofoten Islands <br />
								<span>Norway</span>
							</h2>
						</div>
					</div>
					<div className='box'>
						<div className='imgBx'>
							<img
								src={destination2}
								alt='d2'
								className='fitBg'
							/>
						</div>
						<div className='content'>
							<h2>
								Koh Samui <br />
								<span>Thailand</span>
							</h2>
						</div>
					</div>
					<div className='box'>
						<div className='imgBx'>
							<img
								src={destination3}
								alt='d2'
								className='fitBg'
							/>
						</div>
						<div className='content'>
							<h2>
								Bartan Island <br />
								<span>India</span>
							</h2>
						</div>
					</div>
				</div>
				<a href='#' className='btn'>
					View More
				</a>
			</section>
			<section className='banner3'>
				<img src={banner3} alt='banner3' className='fitBg' />
			</section>
			<section className='footer'>
				<ul className='sci'>
					<li data-aos='fade-left' data-aos-delay='200'>
						<a
							href='https://www.facebook.com/srushti.sangawar.3'
							target='_blank'
						>
							<img src={facebook} alt='' />
						</a>
					</li>

					<li data-aos='fade-left' data-aos-delay='200'>
						<a
							href='https://www.instagram.com/swagatkumarflute/'
							target='_blank'
						>
							<img src={instagram} alt='' />
						</a>
					</li>
				</ul>
			</section>
		</>
	);
}

export default App;
